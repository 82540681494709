
export const getOptions = (listings, type) => {
    const options = listings && Array.isArray(listings) &&
        listings.map(
            item => item.attributes.publicData[type] && item.attributes.publicData[type]);
            
    return options;
};

export const getOptionsByKey = (listings, type) => {
    const options = getOptions(listings, type);
    const filterOptions = options && Array.isArray(options) && [...new Set(options)].map(item => {
        return { key: item, label: item };
    }).filter(i => i?.key !== null && typeof i?.key !== 'undefined');
     return filterOptions;
};

export const getOptionsFromSize = (listings, type) => {
    const options = getOptions(listings, type);
    const filterOptions = options && Array.isArray(options.flat()) && [...new Set(options.flat())].map(item => {
        return { key: item, label: item };
    }).filter(i => i?.key !== null && typeof i?.key !== 'undefined');
    return filterOptions;
};


export const getOptionsByName =(name, options)=>{
    switch (name) {
        case 'category':
          return options.categoryOptions;
          case 'subcategory':
          return options.subCategoryOptions;
        case 'size':
          return options.sizeOptions;
        case 'brand':
          return options.brandOptions;
        case 'colour':
          return options.colourOptions;
        default:
          return false;
      }
}

